import React from 'react';
import { Grid, FormControl, Slider, InputLabel, Box } from '@mui/material'
import TextInput from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import tinygradient from "tinygradient";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SelectInput from './selectbox';

const gradientOptions = [
    {
        name: "rgb",
        value: "rgb",
        colors:[
            "#FF0000",
            "#00FF00",
            "#0000FF",
        ]
    },
    {
        name: "cool",
        value: "cool",
        colors:[
            "#0000FF",
            "#A020F0",
            "#00FF00",
        ]
    },
    {
        name: "warm",
        value: "warm",
        colors:[
            "#FF0000",
            "#FFA500",
            "#FFFF00",
        ]
    },
]

class ColorGradient extends React.Component {
    state = {
        gradientPicker:"custom"
    }
    setGradientPicker = (e) => {
        const option = gradientOptions.find(o => o.value == e.target.value)
        if (option) {
            this.props.input.splice(0, this.props.input.length, ...option.colors)
            this.resetBreakpoints();
            let output = this.getOutput();
            let e = { target: { name: this.props.name, value: output } };
            this.props.onChange(e)
        }
        this.setState({gradientPicker:e.target.value})
    }
    getOutput = () => {
        const input = this.props.input;
        const breakpoints = this.props.breakpoints;
        let colors;
        if (input.length > 1) {
            colors = input.map((c, index) => {return {color:c, pos:breakpoints[index]}});
        }
        else {
            colors = [...input, ...input];
        }
        const gradient = tinygradient(colors);
        const result = gradient.rgb(100).map(tinycolor => tinycolor.toHexString());
        return result;
    }

    resetBreakpoints = () => {
        const colors = this.props.input;
        const breakpoints = this.props.breakpoints;
        breakpoints.splice(0, breakpoints.length);
        const division = colors.length>1 ? 1/(colors.length-1):0;
        for(let i=0; i<colors.length; i++) {
            breakpoints.push(i*division);
        }
    } 

    addColor = () => {
        let input = this.props.input;
        input.push("#000000");
        this.resetBreakpoints();
        let output = this.getOutput();
        let e = { target: { name: this.props.name, value: output } };
        this.props.onChange(e)
    }

    removeColor = (index) => {
        let input = this.props.input;
        if (input.length > 1) {
            input.splice(index, 1);
            this.resetBreakpoints();
            let output = this.getOutput();
            let e = { target: { name: this.props.name, value: output } };
            this.props.onChange(e)
        }
    }

    changeColor = (index, value) => {
        let input = this.props.input;
        input[index] = value;
        let output = this.getOutput();
        let e = { target: { name: this.props.name, value: output } };
        this.props.onChange(e)
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const reorder = (list, startIndex, endIndex) => {
            const result = list;
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            return result;
        };
        const input = reorder(
            this.props.input,
            result.source.index,
            result.destination.index
        );
        let output = this.getOutput();
        let e = { target: { name: this.props.name, value: output } };
        this.props.onChange(e)
    }

    setBreakpoints = (val) => {
        this.props.breakpoints.splice(0, this.props.breakpoints.length, ...val)
        let output = this.getOutput();
        let e = { target: { name: this.props.name, value: output } };
        this.props.onChange(e)
    }

    render() {
        let gradient = [];
        for (let i = 0; i < 100; i += 5) {
            gradient.push(this.props.output[i]);
        }

        const getItemStyle = (isDragging, draggableStyle) => ({
            userSelect: "none",
            margin: '0',
            borderBottomStyle: 'solid',
            borderBottomWidth: 'thin',
            borderBottomColor: 'rgba(0,0,0,.4)',
            background: isDragging ? "rgba(0,0,0,0)" : "rgba(0,0,0,0)",

            ...draggableStyle
        });
        const getListStyle = isDraggingOver => ({
            background: isDraggingOver ? "rgba(0,0,0,0)" : "rgba(0,0,0,0)",
            width: "100%"
        });

        return (
            <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                <InputLabel style={{ fontSize: "small" }}>gradient</InputLabel>
                <FormControl fullWidth style={{marginBottom:"10px"}}>
                    <SelectInput 
                        key={"gradient-picker"} 
                        name={"gradient-picker"}
                        value={this.state.gradientPicker} 
                        onChange={this.setGradientPicker} 
                        options={gradientOptions} 
                        label={"presets"} 
                        />
                </FormControl>
                <FormControl fullWidth>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        {
                            gradient.map((hex, index) => {
                                return (
                                    <Grid key={index} item xs style={{ backgroundColor: hex, height: "10px" }}></Grid>
                                )
                            })
                        }
                    </Grid>
                </FormControl>
                <Slider
                    style={{paddingTop:"0"}}
                    fullWidth
                    track={false}
                    value={this.props.breakpoints || []}
                    onChange={(e, val) => this.setBreakpoints(val)}
                    step={.01}
                    min={0}
                    max={1}
                />
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {
                                    this.props.input.map((colorHex, index) => (
                                        <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}

                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item {...provided.dragHandleProps}>
                                                            <i className="fas fa-palette"></i>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <FormControl fullWidth>
                                                                <TextInput
                                                                    variant="outlined"
                                                                    label={`color ${index + 1}`}
                                                                    value={colorHex}
                                                                    margin="dense"
                                                                    size="small"
                                                                    onChange={e => this.changeColor(index, e.target.value)}
                                                                    inputProps={{
                                                                        name: this.props.name,
                                                                        type: 'color',
                                                                    }}
                                                                />
                                                            </FormControl>

                                                        </Grid>
                                                        <Grid item>
                                                            <ToggleButton size="small" onClick={() => this.removeColor(index)}><CloseIcon /></ToggleButton>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <FormControl fullWidth>
                    <ToggleButton onClick={this.addColor}>
                        <AddIcon />
                    </ToggleButton>
                </FormControl>

            </Box>

        );
    }
}
export default ColorGradient;