import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { GetComponentIcon, IsNestedType } from '../../factories/canvasData';
import { Fab, Grid } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import TuneIcon from '@mui/icons-material/Tune';
import { MOBILE_WIDTH } from '../../constants';

class TreeItem extends React.Component {
    select = (e) => {
        return this.props.select(this.props.id);
    }
    tune = (e) => {
        e.stopPropagation();
        if (!this.props.isSelected) {
            this.props.select(this.props.id);
        }
        this.props.tune();
    }
    delete = (e) => {
        e.stopPropagation();
        e.preventDefault();
        return this.props.delete(this.props.id);
    }
    toggleVisability = (e) => {
        e.stopPropagation();
        //e.preventDefault();
        return this.props.toggleVisibility(this.props.id, !this.props.visible);
    }
    copy = (e) => {
        e.stopPropagation();
        //e.preventDefault();
        return this.props.copy(this.props.id);
    }
    onToggle = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.onToggle(this.props.id)
    }
    render() {
        const isMobileMode = window.innerWidth <= MOBILE_WIDTH;
        const style = {
            padding: "5px",
            background: this.props.isSelected ? "rgba(50,50,50,1)" : "rgba(0,0,0,0)",
        }
        const arrow = <i key={Math.random()} className={this.props.isOpen ? "fas fa-caret-down" : "fas fa-caret-right"}></i>;
        const isNestedType = IsNestedType(this.props.type);
        const btnStyle = { outline: "none", width: "25px", height: "25px", minHeight: "25px", marginRight: "2px" }
        const visibleIcon = this.props.visible ? (<VisibilityIcon fontSize="small" />) : (<VisibilityOffIcon fontSize="small" />);
        return (
            <div onClick={this.select} style={style}>
                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                    {
                        isNestedType &&
                        <Grid item xs={1}>
                            <div onClick={this.onToggle}>{arrow}</div>
                        </Grid>
                    }
                    <Grid item xs={1}>
                        <span><i className={GetComponentIcon(this.props.type)}></i></span>
                    </Grid>
                    <Grid item xs>
                        {this.props.name}
                    </Grid>
                    <Grid item>
                        <Grid container spacing={.75} alignItems="center" justifyContent="flex-end">
                            <Grid item>
                                <Fab style={btnStyle} title="visible" onClick={this.toggleVisability}>{visibleIcon}</Fab>
                            </Grid>
                            {!isMobileMode ||
                                <Grid item>
                                    <Fab style={btnStyle} title="tune" onClick={this.tune}><TuneIcon /></Fab>
                                </Grid>
                            }
                            <Grid item>
                                <Fab style={btnStyle} title="copy" onClick={this.copy}><i className="fa fa-copy"></i></Fab>
                            </Grid>
                            <Grid item>
                                <Fab style={btnStyle} title="delete" onClick={this.delete}><CloseIcon /></Fab>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default TreeItem;
