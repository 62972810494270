import React from 'react';
import { Box, InputLabel } from '@mui/material'
import SelectInput from './selectbox';
import SliderInput from './slider';
import SwitchInput from './switch';
import { MODEL_TYPE, PHYSICS_SHAPE_TYPES, PHYSICS_SHAPE_TYPE_BOX, PHYSICS_SHAPE_TYPE_SPHERE } from '../../factories/canvasData';
class Physics extends React.Component {
    setValue = (e) => {
        let value = e.target.value;
        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }
        let physics = this.props.value;
        if (!physics){
            physics = {}
        }
        physics[e.target.name] = value;
        this.props.onChange({ target: { name: 'physics', value: physics } });
    }
    render() {
        return (
            <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                <InputLabel style={{ fontSize: "small" }}>physics</InputLabel>
                <SwitchInput name="enable" value={this.props.value?.enable} onChange={this.setValue} label="enable" />
                {
                    this.props.value?.enable ? (
                        <React.Fragment>
                            <SliderInput name="mass" value={this.props.value.mass} onChange={this.setValue} min={0} max={1000} step={.01} label="mass" />
                            <SliderInput name="restitution" value={this.props.value.restitution} onChange={this.setValue} min={.01} max={1} step={.01} label="restitution" />
                            <SliderInput name="friction" value={this.props.value.friction} onChange={this.setValue} min={.01} max={100} step={.01} label="friction" />
                            {
                                this.props.componentType === MODEL_TYPE ? (
                                    <React.Fragment>
                                        <SelectInput name="physicsShape" value={this.props.value.physicsShape} onChange={this.setValue} options={PHYSICS_SHAPE_TYPES} label="physics shape" />
                                        <SwitchInput name="debug" value={this.props.value?.debug} onChange={this.setValue} label="debug" />
                                        <React.Fragment>
                                            <SliderInput name="width" value={this.props.value.width} onChange={this.setValue} min={.01} max={20} step={.01} label="width" />
                                            <SliderInput name="height" value={this.props.value.height} onChange={this.setValue} min={.01} max={20} step={.01} label="height" />
                                            <SliderInput name="depth" value={this.props.value.depth} onChange={this.setValue} min={.01} max={20} step={.01} label="depth" />
                                        </React.Fragment>

                                    </React.Fragment>
                                )
                                    : ""
                            }
                        </React.Fragment>
                    )
                        : ""
                }
            </Box>
        );
    }
}
export default Physics;