import React from 'react';
import { Box, InputLabel } from '@mui/material'
import { connect } from 'react-redux';
import SliderInput from '../inputs/slider';
import { userPreferencesSettingChange } from '../../../redux/actions/userPreferencesActions';
import SwitchInput from '../inputs/switch';
import { ASPECT_RATIO_OPTIONS, RECORD_FPS_OPTIONS } from '../../factories/canvasData';
import SelectInput from '../inputs/selectbox';
import { getSupportedRecordingMimeTypes } from '../../utility/utility';

class GlobalEditor extends React.Component {
    userPreferencesChange = (e) => {
        let value = e.target.value;
        if (e.target.type === "number" || e.target.type === "range") {
            value = Number(value);
        }
        else if (e.target.type === "checkbox") {
            value = e.target.checked;
        }

        this.props.userPreferencesSettingChange(e.target.name, value);
    }
    cameraChange = (e) => {
        this.props.sharedSettingChange(e.target.name, e.target.value)
    }
    render() {
        const recordingTypes = getSupportedRecordingMimeTypes().map(v => {
            return  {
                name: `.${v.extension} - ${v.mime}`,
                value: v.mime,
            }
        })
        return (
            <Box p={1}>
                <SliderInput value={this.props.userPreferences.globalVolume} onChange={this.userPreferencesChange} min={0} max={1} step={.01} label={"global volume"} name={"globalVolume"} />
                <SelectInput name="aspectRatio" value={this.props.userPreferences.aspectRatio} onChange={this.userPreferencesChange} options={ASPECT_RATIO_OPTIONS} label="aspect ratio" />
                <SelectInput name="fps" value={this.props.userPreferences.fps} onChange={this.userPreferencesChange} options={RECORD_FPS_OPTIONS} label="target fps" />
                <SwitchInput value={this.props.userPreferences.displayFPS} onChange={this.userPreferencesChange} label={"display fps"} name={"displayFPS"} />
                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                    <InputLabel style={{ fontSize: "small" }}>recording</InputLabel>
                    <SelectInput name="videoFormat" value={this.props.userPreferences.videoFormat} onChange={this.userPreferencesChange} options={recordingTypes} label="video format" />
                </Box>
            </Box>
        );
    }
}

const mapCanvasData = (state) => { return { userPreferences: state.userPreferences } };

const mapDispatch = {
    userPreferencesSettingChange,
};

export default connect(mapCanvasData, mapDispatch)(GlobalEditor);