import React from 'react';
import { FormControl, Grid, Box } from '@mui/material'
import SelectInput from './selectbox';
import DraggableGraph from './chart';
import { getDefaultFilterData } from '../../factories/canvasData';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';

class AudioInput extends React.Component {
    setValue = (e) => {
        this.props.value[e.target.name] = e.target.value;
        this.props.onChange({ target: { name: 'audio', value: this.props.value } });
    }
    setFilter = (e) => {
        let filter = e.target.value;
        let data = [...this.props.value.filterData];
        switch (filter) {
            case "lowpass":
                data = [
                    {
                        x: 0,
                        y: 100
                    },
                    {
                        x: 256,
                        y: 100
                    },
                    {
                        x: 512,
                        y: 0
                    },
                    {
                        x: 1024,
                        y: 0
                    }
                ];
                break;
            case "highpass":
                data = [
                    {
                        x: 0,
                        y: 0
                    },
                    {
                        x: 512,
                        y: 0
                    },
                    {
                        x: 768,
                        y: 100
                    },
                    {
                        x: 1024,
                        y: 100
                    }
                ];
                break;
            case "bandpass":
                data = [
                    {
                        x: 0,
                        y: 0
                    },
                    {
                        x: 512,
                        y: 100
                    },
                    {
                        x: 1024,
                        y: 0
                    }
                ];
                break;
            case "none":
                data = getDefaultFilterData();
                break;
        }

        this.setValue(e)
        this.setValue({ target: { name: "filterData", value: data } });
    }
    setFilterData = (d) => {
        this.setValue({ target: { name: "filterData", value: [...d] } });
        if (this.props.value.filter != "custom") {
            this.setValue({ target: { name: "filter", value: "custom" } });
        }
    }
    render() {
        const audioInputs = [
            { name: "none", value: "none" },
            ...this.props.inputs,
        ]
        const dataOptions = [
            { name: "frequency", value: "frequency" },
            { name: "time domain", value: "time domain" }
        ]
        const filterOptions = [
            { name: "none", value: "none" },
            { name: "low pass", value: "lowpass" },
            { name: "high pass", value: "highpass" },
            { name: "band pass", value: "bandpass" },
            { name: "custom", value: "custom" },
        ]
        const disable = this.props.value.input == "none";
        return (
            <FormControl fullWidth margin='none'>
                <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                    <Grid container spacing={1}>
                        <Grid item>
                            <div style={{ width: "40px", height: "40px", borderRadius: "5px", textAlign: "center", backgroundColor: "rgb(50,50,50)", paddingTop: "8px", marginTop:"8px"}}>
                                <SettingsInputComponentIcon />
                            </div>
                        </Grid>
                        <Grid item xs>
                            <SelectInput name={"input"} value={this.props.value.input} onChange={this.setValue} options={audioInputs} label="audio input" />
                        </Grid>
                    </Grid>
                    {disable
                        ? ""
                        : (
                            <React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item sm={6}>
                                        <SelectInput name={"data"} value={this.props.value.data} onChange={this.setValue} options={dataOptions} label="data" />
                                    </Grid>
                                    <Grid item sm={6}>
                                        <SelectInput name={"filter"} value={this.props.value.filter} onChange={this.setFilter} options={filterOptions} label="filter" />
                                    </Grid>
                                </Grid>
                                <DraggableGraph id={"audio-chart"} data={this.props.value.filterData} onChange={this.setFilterData} xmin={0} xmax={1024} ymin={0} ymax={200} />
                            </React.Fragment>
                        )}
                </Box>
            </FormControl>
        );
    }
}
export default AudioInput;