import React from 'react';
import { Box, Button, Fab, FormControl, Grid, InputLabel, LinearProgress } from '@mui/material'
import 'babylonjs-loaders';
import { blobToBase64, download, getFileExtension } from '../../utility/utility';
import { GetSupportedAssetTypes } from '../../factories/canvasData';
import CloseIcon from '@mui/icons-material/Close';
import { _assets } from '../../factories/assets';
import ImageGenerator from '../imageGeneratorEditor/imageGenerator';
import GetAppIcon from '@mui/icons-material/GetApp';

class FileInput extends React.Component {
    state = {
        id: undefined,
        name: "",
        base64: undefined,
        loading: false,
        prompt: "",
        isImageGeneratorModalOpen: false,
    };
    openImageGenerator = () => {
        this.setState({ isImageGeneratorModalOpen: true });
    }
    fileUpload = (e) => {
        const _this = this;
        let value = e.target.files;
        if (!_this.props.userId) {
            alert("Please login to upload files");
            return;
        }
        if (e.target.files.length == 1) {
            const supportedFileTypes = GetSupportedAssetTypes(_this.props.assetType);
            var file = value[0];
            let fileExtension = getFileExtension(file.name);
            if (supportedFileTypes.includes(fileExtension)) {
                _this.applyFile(file);
            } else {
                alert(`${file.name} is not supported. Please upload a file of type: (${supportedFileTypes.join(', ')})`)
            }
        }
    }

    applyFile = (file) => {
        let fileExtension = getFileExtension(file.name);
        file.assetType = this.props.assetType;
        file.extension = fileExtension;
        this.setState({ assetLoading: true });
        _assets.create(file).then(res => {
            console.log(res);
            this.props.onChange({ target: { name: this.props.name, value: res.id } })
        }).catch(e => {
            alert("Failed to upload asset.. please try again");
            this.setState({ assetLoading: false });
        })
    }

    loadImgViewer = (base64) => {
        const viewerDOM = document.getElementById(this.getAssetViewerId());
        if (viewerDOM) {
            viewerDOM.innerHTML = "";
            const img = document.createElement("img");
            img.style.width = "100%";
            img.style.height = "175px";
            img.style.borderRadius = "5px";
            img.src = base64;
            viewerDOM.appendChild(img)
        }
    }
    componentWillReceiveProps(nextProps) {
        this.updateProps(nextProps)
    }
    componentDidMount() {
        this.updateProps(this.props)
    }
    updateProps = (nextProps) => {
        const _this = this;
        if (nextProps.value) {
            if (_this.state.id != nextProps.value) {
                _this.setState({ assetLoading: true });
                console.log(nextProps.value);
                _assets.get(nextProps.value).then(asset => {
                    console.log(asset);
                    if (asset) {
                        _this.setState({ assetLoading: false });
                        if (asset) {
                            _this.setState({ id: asset.id, name: asset.name, base64: asset.base64 })
                            _this.loadImgViewer(asset.base64);
                        }
                    }
                })
            }
        }
        else {
            _this.setState({
                name: "",
                loading: false,
            })
            const viewerDOM = document.getElementById(this.getAssetViewerId());
            if (viewerDOM) {
                viewerDOM.innerHTML = "";
            }
        }
    }
    getAssetViewerId = () => {
        return `asset-viewer-${this.props.name}`;
    }
    delete = () => {
        this.props.onChange({ target: { name: this.props.name, value: undefined } });
        this.setState({ id: undefined, name: undefined, base64: undefined })
    }
    download = () => {
        download(this.state.base64, this.state.name);
    }

    imageGeneratorOnClose = (imageFile) => {
        const _this = this;
        _this.setState({ isImageGeneratorModalOpen: false });
        if (imageFile) {
            _this.applyFile(imageFile);
        }
    }

    render() {
        const closeBtnStyle = { outline: "none", width: "20px", height: "20px", minHeight: "20px", marginBottom: ".5rem", marginLeft:"5px" }
        const btnText = `upload file (${GetSupportedAssetTypes(this.props.assetType).join(", ")})`
        return (
            <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
                <InputLabel style={{ fontSize: "small" }}>{this.props.name}</InputLabel>
                <FormControl fullWidth>
                    <Button
                        style={{ height: "30px" }}
                        variant="contained"
                        onClick={this.openImageGenerator}
                    >
                        <i class="fas fa-magic"></i>&nbsp;generate image
                    </Button>
                    <ImageGenerator userId={this.props.userId} open={this.state.isImageGeneratorModalOpen} onClose={this.imageGeneratorOnClose} />
                </FormControl>
                <div style={{ width: "100%", textAlign: "center" }}>or</div>
                <FormControl fullWidth>
                    <Button
                        style={{ height: "30px" }}
                        variant="contained"
                        component="label"
                    >
                        {<i class="fas fa-file-image"></i>}&nbsp;{btnText}
                        <input
                            type="file"
                            onChange={this.fileUpload}
                            hidden
                        />
                    </Button>
                </FormControl>
                <hr />
                {!this.state.name ||
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <InputLabel style={{ fontSize: "small" }}>{this.state.name}</InputLabel>
                        </Grid>
                        <Grid item>
                            <Fab style={closeBtnStyle} onClick={this.download}><GetAppIcon /></Fab>
                            <Fab style={closeBtnStyle} onClick={this.delete}><CloseIcon /></Fab>
                        </Grid>
                    </Grid>
                }
                {!this.state.assetLoading ? "" : <LinearProgress />}
                <div id={this.getAssetViewerId()}></div>
            </Box>
        );
    }
}
export default FileInput;