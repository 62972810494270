import * as React from 'react';
import MaterialTable from 'material-table';
import { Box } from '@mui/material'
import _users from '../services/usersService';
import { connect } from 'react-redux';
import _execApiAssets from '../services/execApiAssetsService';

class UserManager extends React.Component {
    state = {
        users: [],
        isLoading: true,
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        if (this.props.user) {
            return _users.getUsers()
                .then(users => {
                    this.setState({ users: users, isLoading:false })
                })
        }
        else {
            this.setState({ users: [] })
        }
    }

    render() {
        const tableRef = React.createRef();
        return (
            <Box p={1}>
                <MaterialTable
                    tableRef={tableRef}
                    isLoading={this.state.isLoading}
                    options={{
                        sorting: true,
                        search: true,
                        paging: true,
                    }}
                    title="Users"
                    columns={[
                        // { title: 'Id', field: 'id' },
                        { title: '', field: 'image', editable: false, render: row => (<img style={{height:"50px", width:"50px"}} src={row.profileImageId ? _execApiAssets.getFileUrl(row.profileImageId) : "/content/images/logo.png"}></img>) },
                        { title: 'Email', field: 'email', editable: false },
                        { title: 'UserName', field: 'userName', validate: u => u.displayName == "" ? { isValid: false, helperText: "required" } : { isValid: true } },
                        { title: 'First', field: 'firstName', validate: u => u.firstName == "" ? { isValid: false, helperText: "required" } : { isValid: true } },
                        { title: 'Last', field: 'lastName', validate: u => u.lastName == "" ? { isValid: false, helperText: "required" } : { isValid: true } },
                        // {
                        //     title: 'Role',
                        //     field: 'userRole',
                        //     editComponent: props => (
                        //         <Select
                        //             id="userrole"
                        //             value={props.value || false}
                        //             onChange={e => props.onChange(e.target.value)}
                        //         >
                        //             <MenuItem value={"admin"}>admin</MenuItem>
                        //             <MenuItem value={"basic"}>basic</MenuItem>
                        //         </Select>),
                        // },
                        // {
                        //     title: 'Is Active',
                        //     field: 'isActive',
                        //     type: "boolean",
                        //     // render: row => (<span>{String(typeof row.isActive === "undefined" ? true : row.isActive)}</span>),
                        //     // editComponent: props => (
                        //     //     <Select
                        //     //         id="isActive"
                        //     //         value={typeof props.value === "undefined" ? true : props.value}
                        //     //         onChange={e => props.onChange(e.target.value)}
                        //     //     >
                        //     //         <MenuItem value={true}>true</MenuItem>
                        //     //         <MenuItem value={false}>false</MenuItem>
                        //     //     </Select>),
                        // },
                    ]}
                    data={this.state.users}
                    // editable={{
                    //     onRowUpdate: (newData, oldData) => {
                    //         return _users.patchUser(newData).then(_ => this.getData())
                    //     },
                    // }}
                />
            </Box>
        )
    }

}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(UserManager);