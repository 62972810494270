import React from 'react';
import { FormControl } from '@mui/material'
import TextInput from '@mui/material/TextField';

class TextInputBox extends React.Component {
    state = {};
    keyPress = (e) => {
        if (!this.props.multiline && e.keyCode === 13)
            e.target.blur();
    }
    componentWillReceiveProps(nextProps){
          this.setState({ value: nextProps.value })
    }
    componentDidMount(){
        this.setState({ value: this.props.value })
    }
    render() {
        return (
            <FormControl fullWidth margin='none'>
                <TextInput
                    variant="outlined"
                    label={this.props.label}
                    value={this.state.value}
                    margin="dense"
                    size="small"
                    onKeyDown={this.keyPress}
                    onChange={e => this.setState({value:e.target.value})}
                    onBlur={e => {e.target.value = this.state.value; this.props.onChange(e)}}
                    multiline={this.props.multiline}
                    rows={this.props.multiline?this.props.lineCount:1}
                    inputProps={{
                        name: this.props.name,
                        type: this.props.type,
                        max: this.props.max,
                        min: this.props.min,
                        step: this.props.step,
                        'aria-labelledby': this.props.name,
                    }}
                />
            </FormControl>
        );
    }
}
export default TextInputBox;