import React from 'react';
import { Fab, Grid, FormControl, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import CameraIcon from '@mui/icons-material/Camera';
import SelectInput from '../inputs/selectbox';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import SliderInput from '../inputs/slider';
import { videoData } from '../../factories/videoData';
// import VideoController from './videoController';
import { supportedVideoTypes, videoInputOptions } from '../../factories/canvasData';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const blob = window.URL || window.webkitURL;
if (!blob) {
    alert('this browser does not support loading video files');
}

// const supportedVideoTypes = [
//     "video/x-wav",
//     "video/ogg",
//     "video/mpeg",
//     "video/wav"
// ]

class VideoInput extends React.Component {
    state = {
        inputs: [],
    }
    delete = (e) => {
        e.stopPropagation();
        e.preventDefault();
        return this.props.delete(this.props.input.id);
    }

    onChooseFile = (e) => {
        let value = e.target.files;
        for (var i = 0; i < value.length; i++) {
            var file = value[i];
            if (supportedVideoTypes.includes(file.type)) {
                const videoUrl = blob.createObjectURL(file);
                this.props.propsChange(this.props.input.id, "videoFileUrl", videoUrl)
                this.props.propsChange(this.props.input.id, "videoFileName", file.name)
                this.props.propsChange(this.props.input.id, "videoFileType", file.type)
            } else {
                alert(`${file.type} files are not supported: (${supportedVideoTypes.join(', ')})`)
            }
        }
    }

    onChooseVideoInput = (e) => {
        const input = this.state.inputs.find(i => i.value == e.target.value);
        this.props.propsChange(this.props.input.id, "deviceName", input.name)
        this.props.propsChange(this.props.input.id, "deviceId", input.value)
    }

    onSettingChange = (e) => {
        let value = e.target.value;
        if (e.target.type === "number" || e.target.type === "range") {
            value = Number(value);
        }
        else if (e.target.type === "checkbox") {
            value = e.target.checked;
        }

        this.props.propsChange(this.props.input.id, e.target.name, value);
    }

    render() {
        if (this.props.input.type === "webcam") {
            navigator.mediaDevices.getUserMedia({ video: true }).then((_) => {
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    let inputs = devices.filter(d => d.kind == 'videoinput').map(d => { return { value: d.deviceId, name: d.label || "unknown" } })
                    this.setState({ inputs });
                });
            })
        }

        // videoData.loaded[`${this.props.input.id}-input`] = {
        //     updateTime: this.updateTime,
        // };

        const btnStyle = { outline: "none", width: "40px", height: "40px", minHeight: "25px" }
        let input = "";
        let icon = "";
        switch (this.props.input.type){
            case "webcam": 
                input = (<SelectInput name={"video-input"} value={this.props.input.deviceId} onChange={this.onChooseVideoInput} options={this.state.inputs} label={"input"} ></SelectInput>);
                icon = <CameraIcon />;
                break;
            case "file": 
            input = (<FormControl fullWidth margin='dense'>
                <div className="custom-file">
                    <input type="file" id="file-input" className="custom-file-input" name='file' onChange={this.onChooseFile} style={{ width: "100% " }} />
                    <label className="custom-file-label" style={{ color: "white", borderColor: "rgba(255, 255, 255, 0.09)", backgroundColor: "rgba(255, 255, 255, 0.09)" }}><i className="fa fa-file"></i> {this.props.input.videoFileName}</label>              
                </div>
            </FormControl>)
                icon = <InsertDriveFileIcon />;
                break;
            case "livestream":
                input="";
                icon = <LiveTvIcon />
                break;
            default: 
                input = "";
                break;
        }
        return (
            <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
            <Grid spacing={1} container justifyContent="space-between" alignItems="center">
                    <Grid item style={{ overflowX: "hidden" }}>
                        <div style={{ width: "40px", height: "40px", borderRadius:"5px", textAlign:"center",backgroundColor:"rgb(50,50,50)", paddingTop:"8px" }} {...this.props.dragHandleProps}>
                            {icon}
                        </div>
                    </Grid>
                    <Grid item xs style={{ overflowX: "hidden" }}>
                        <SelectInput name="type" value={this.props.input.type} onChange={this.onSettingChange} options={videoInputOptions} label="type" />
                    </Grid>
                    <Grid item>
                        <Fab style={btnStyle} title="delete" onClick={this.delete}><CloseIcon /></Fab>
                    </Grid>
                </Grid>
                {input}
                {/* {this.props.input.type == "file" ? <VideoController input={this.props.input} propsChange={this.props.propsChange}/> : ""} */}
            </Box >
        )
    }
}

export default VideoInput;
