import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@mui/material';
import { appSettingChange } from '../../../redux/actions/appSettingsActions';
import AudioController from '../audioInputsEditor/audioController';
import { inputPropChange } from '../../../redux/actions/inputActions';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MicController from '../audioInputsEditor/micController';

class MediaControls extends React.Component {
    state = { collapse: false }
    onChange = (e, val) => {
        if (val) {
            this.props.appSettingChange("gizmo", undefined);
            this.props.appSettingChange("view", val);
        }
    }

    // componentDidMount() {
    //     var wavesurfer = WaveSurfer.create({
    //         container: '#waveform',
    //         waveColor: 'violet',
    //         progressColor: 'purple'
    //     });
    //     if (this.props.inputs[0].audioFileUrl) {
    //         wavesurfer.load(this.props.inputs[0].audioFileUrl);
    //     }
    // }

    render() {
        return !this.props.inputs.length 
        ? ""
        : (
            <div id="bottom-menu" style={{
                pointerEvents:"auto",
                border: "1px solid rgb(50,50,50)",
                width: '100%',
                height: this.state.collapse? "0" : "auto",
                overflow: "hidden",
                backgroundColor: "rgba(20, 20, 20, .9)",
                paddingTop: this.state.collapse?"0" : "10px",
                paddingLeft: "5px",
                paddingRight: "5px",
                zIndex: 1, 
                transition: "all 0.6s ease-in-out",
            }}>
                <div 
                onClick={() => this.setState({collapse: !this.state.collapse})}
                style={{
                        position: "fixed",
                        left: "calc(50% - 10px)",
                        width: "20px",
                        cursor: "pointer",
                        height: "11px",
                        marginTop: this.state.collapse? "-30px" : "-22px",
                        borderTopLeftRadius: "5px",
                        borderTopRightRadius: "5px",
                        transition: "all 0.6s ease-in-out",
                        }}>
                    {
                        this.state.collapse 
                        ? (<ExpandLessIcon style={{color:"grey"}} fontSize='large' />)
                        : (<ExpandMoreIcon style={{color:"grey"}} fontSize='large' />)
                    }
                </div>
                <Box p={0} pt={0} style={{height:this.state.collapse?"0":""}}>
                    {this.props.inputs.map(i => {
                        if (i.type == "file") {
                            return (
                                <AudioController input={i} propsChange={this.props.inputPropChange}/>
                            )
                        }
                        else if(i.type == "mic") {
                            return (
                                <MicController input={i} propsChange={this.props.inputPropChange} />
                            )
                        }
                    })}
                </Box>
            </div>
        );
    }
}

const mapCanvasData = (state) => { return { appSettings: state.appSettings, inputs: state.inputs } };

const mapDispatch = {
    appSettingChange: appSettingChange,
    inputPropChange: inputPropChange,
};

export default connect(mapCanvasData, mapDispatch)(MediaControls);