import React from 'react';
import { FormControl, Grid } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton';
import TextInput from '@mui/material/TextField';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

class Vector3 extends React.Component {

    render() {
        let icon;
        switch (this.props.name) {
            case "position": icon = (<OpenWithIcon />); break;
            case "rotation": icon = (<RotateRightIcon />); break;
            case "scaling": icon = (<AspectRatioIcon />); break;
        }

        return (
            <FormControl fullWidth margin='none'>
                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                    {
                        this.props.gizmoChange
                            ? (
                                <Grid item>
                                    <ToggleButton
                                        size="small"
                                        value={this.props.gizmo == this.props.name}
                                        selected={this.props.gizmo == this.props.name}
                                        onChange={(e) => this.props.gizmoChange(this.props.gizmo == this.props.name ? "" : this.props.name)}
                                        name={"gizmo"}
                                    >
                                        {icon}
                                    </ToggleButton>
                                </Grid>
                            )
                            : ""
                    }

                    <Grid item xs>
                        <TextInput
                            variant="outlined"
                            fullWidth
                            label="x"
                            value={this.props.value.x}
                            margin="dense"
                            onChange={(e) => this.props.onChange(this.props.name, e)}
                            inputProps={{
                                name: "x",
                                step: this.props.step,
                                min: this.props.min,
                                max: this.props.max,
                                type: 'number',
                                'aria-labelledby': this.props.name,
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextInput
                            variant="outlined"
                            fullWidth
                            label="y"
                            value={this.props.value.y}
                            margin="dense"
                            onChange={(e) => this.props.onChange(this.props.name, e)}
                            inputProps={{
                                name: "y",
                                step: this.props.step,
                                min: this.props.min,
                                max: this.props.max,
                                type: 'number',
                                'aria-labelledby': this.props.name,
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextInput
                            variant="outlined"
                            fullWidth
                            label="z"
                            value={this.props.value.z}
                            margin="dense"
                            onChange={(e) => this.props.onChange(this.props.name, e)}
                            inputProps={{
                                name: "z",
                                step: this.props.step,
                                min: this.props.min,
                                max: this.props.max,
                                type: 'number',
                                'aria-labelledby': this.props.name,
                            }}
                        />
                    </Grid>
                </Grid>
            </FormControl>
        );
    }
}
export default Vector3;