import React from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import _venue from '../services/venueService';
import _venueAccess from '../services/venueAccessService';
import { Box, Modal, Paper } from '@mui/material';
import ProjectAccess from './projectAccess';

class MyCollaborativeProjects extends React.Component {
    state = {
        projects: [],
        sharingModal: false,
        sharingProjectId: undefined,
        isLoading: true,
    }

    async componentDidMount() {
        await this.getData();
    }

    getData = async () => {
        if (this.props.user.email) {
            let projectAccesses = [];
            return _venueAccess.getVenueAccess()
                .then(async (pas) => {
                    if (pas) {
                        for (let projectAccess of pas) {
                            await _venue.getVenue(projectAccess.projectId).then(project => {
                                projectAccess.name = project.name
                                projectAccess.visibility = project.visibility
                                projectAccess.dateModified = project.dateModified
                                projectAccesses.push(projectAccess);
                            }).catch(e => console.log(e));
                        }
                    }
                    else {
                        projectAccesses = [];
                    }

                    this.setState({ projects: projectAccesses, isLoading:false })
                })
        }
    }
    toggleSharingModal = (projectId) => {
        this.setState({ sharingModal: !this.state.sharingModal, sharingProjectId: projectId })
    }
    render() {
        const tableRef = React.createRef();
        const sharingTableRef = React.createRef();
        return (
            <Box p={1}>
                <MaterialTable
                    tableRef={tableRef}
                    isLoading={this.state.isLoading}
                    options={{
                        sorting: true,
                        search: true,
                        paging: true,
                        editable: false,
                        // actionsCellStyle: { justifyContent: 'center', width: '100%', padding: ".25em" },
                        actionsColumnIndex: -1,
                    }}
                    title="Scenes Shared With Me"
                    columns={[
                        { title: 'Name', field: 'name' },
                        // {
                        //     title: 'Visibility',
                        //     field: 'visibility',
                        // },
                        { title: 'Access', field: 'access', type: 'string' },
                        { title: 'Date Last Modified', field: 'dateModified', type: 'date' },
                    ]}
                    data={this.state.projects}
                    actions={[
                        {
                            icon: () => (<i className="fas fa-rocket"></i>),
                            tooltip: 'Launch',
                            onClick: (event, rowData) => {
                                this.props.history.push(`/build/${rowData.projectId}`);
                            }
                        },
                    ]}
                    editable={{
                        onRowDelete: oldData => {
                            return _venueAccess.deleteVenueAccess(oldData.id)
                                .then(p => {
                                    this.getData()
                                });
                        }
                    }}
                />
                <Modal
                    open={this.state.sharingModal}
                    onClose={this.toggleSharingModal}
                >
                    <Paper
                        p={1}
                        style={{
                            position: "absolute",
                            width: "50%",
                            height: "50%",
                            top: "50%",
                            left: "50%",
                            transform: `translate(-50%, -50%)`
                        }}
                    >
                        <ProjectAccess projectId={this.state.sharingProjectId} />
                    </Paper>
                </Modal>
            </Box>
        );
    }
}

const ensureDefaults = (data) => {
    return {
        ...data,
        visibility: data.visibility || "public",
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(MyCollaborativeProjects);