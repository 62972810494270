import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import { MenuItem, Select, Box, TextField, Grid, Fab } from '@mui/material';
import _venueAccess from '../services/venueAccessService';
import _users from '../services/usersService';
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const UserSearch = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const search = useCallback((q) => {
        setIsLoading(true);
        _users.searchUserByUsername(q).then(u => {
            const options = (u ? [u] : []).map((i) => i.email);
            setOptions(options);
            setIsLoading(false);
        });
    })


    const result = (typeof props.value !== "undefined")
        ? (
            <Grid container alignItems="center">
                <Grid item xs={10}>
                    <TextField disabled value={props.value}></TextField>
                </Grid>
                <Grid item xs={2}>
                    <Fab style={{width:"36px", height:"36px"}} variant="outlined" onClick={() => props.onChange()}><i className="fa fa-times"></i></Fab>
                </Grid>
            </Grid>
        )
        : (
            <AsyncTypeahead
                id="sharing-user-search"
                positionFixed
                onChange={(val) => props.onChange(val[0])}
                filterBy={() => true}
                isLoading={isLoading}
                minLength={3}
                onSearch={search}
                options={options}
                placeholder="Search for a user by user name.."
                renderMenuItemChildren={(option, props) => (
                    <div><i className="fas fa-user"></i> {option}</div>
                )}
            />
        )
    return result;
};

class ProjectAccess extends React.Component {
    state = {
        projectAccess: [],
        searchingUsers: false,
        userOptions: [],
        isLoading: true,
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        return _venueAccess.getVenueAccessByVenueId(this.props.projectId)
            .then(projectAccess => {
                this.setState({ projectAccess: projectAccess, isLoading:false })
            })
    }
    getUserOptions = (email) => {
        this.setState({ searchingUsers: true });
        _users.getUser().then(u => {
            this.setState({ userOptions: u ? [u.email] : [], searchingUsers: false })
        });
    }
    render() {
        const tableRef = React.createRef();
        return (
            <Box p={1}>
                <MaterialTable
                    tableRef={tableRef}
                    isLoading={this.state.isLoading}
                    options={{
                        sorting: true,
                        search: true,
                        paging: false,
                        editable: true,
                        actionsCellStyle: { display: 'flex', justifyContent: 'center', width: '100%', padding: ".25em" },
                        actionsColumnIndex: -1,
                    }}
                    title="Share"
                    columns={[
                        {
                            title: 'User',
                            field: 'user',
                            validate: u => !u.user ? { isValid: false, helperText: "required" } : { isValid: true },
                            editComponent: props => (
                                <UserSearch onChange={props.onChange} value={props.value} />
                            )
                        },
                        {
                            title: 'Access',
                            field: 'access',
                            editComponent: props => (
                                <Select
                                    id="access"
                                    value={props.value || "read"}
                                    onChange={e => props.onChange(e.target.value)}
                                >
                                    <MenuItem value={"read"}>read</MenuItem>
                                    <MenuItem value={"write"}>write</MenuItem>
                                </Select>),
                        }
                    ]}
                    data={this.state.projectAccess}
                    editable={{
                        onRowUpdate: (newData, oldData) => {
                            return _venueAccess.saveVenueAccess(ensureDefaults(newData)).then(_ => this.getData());
                        },
                        onRowAdd: newData => {
                            newData.projectId = this.props.projectId
                            return _venueAccess.saveVenueAccess(ensureDefaults(newData)).then(p => this.getData());
                        },
                        onRowDelete: oldData => {
                            return _venueAccess.deleteVenueAccess(oldData.id).then(p => this.getData());
                        }
                    }}
                />
            </Box>
        );
    }
}

const ensureDefaults = (data) => {
    return {
        ...data,
        access: data.access || "read",
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(ProjectAccess);