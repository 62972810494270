import React from 'react';
import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import _venue from '../services/venueService';
import { MenuItem, Select, Box, Modal, Paper } from '@mui/material';
import _venueData from '../services/venueDataService';
import ProjectAccess from './projectAccess';
import _venueAccess from '../services/venueAccessService';
import _assets from '../services/assetsService';


class MyProjects extends React.Component {
    state = {
        projects: [],
        sharingModal: false,
        sharingProjectId: undefined,
        isLoading: true,
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        return _venue.getAllVenues()
            .then(projects => {
                this.setState({ projects: projects, isLoading:false })
            })
    }
    toggleSharingModal = (projectId) => {
        this.setState({ sharingModal: !this.state.sharingModal, sharingProjectId: projectId })
    }
    render() {
        const tableRef = React.createRef();
        const sharingTableRef = React.createRef();
        return (
            <Box p={1}>
                <MaterialTable
                    tableRef={tableRef}
                    isLoading={this.state.isLoading}
                    options={{
                        sorting: true,
                        search: true,
                        paging: true,
                        editable: true,
                        // actionsCellStyle: { justifyContent: 'center', width: '100%', padding: ".25em" },
                        actionsColumnIndex: -1,
                    }}
                    title="Projects"
                    columns={[
                        { title: '', field: 'image', editable: false, render: row => (<img style={{height:"50px", width:"50px"}} src={row.pictureId ? _assets.getFileUrl(row.pictureId) : "/content/images/logo.png"}></img>) },
                        { title: 'Name', field: 'name', validate: u => u.name == "" ? { isValid: false, helperText: "required" } : { isValid: true } },
                        {
                            title: 'Visibility',
                            field: 'visibility',
                            editComponent: props => (
                                <Select
                                    id="visibility"
                                    value={props.value || "public"}
                                    onChange={e => props.onChange(e.target.value)}
                                >
                                    <MenuItem value={"public"}>public</MenuItem>
                                    <MenuItem value={"private"}>private</MenuItem>
                                </Select>),
                        },
                        { title: 'user', field: 'userDisplayName', editable: false },
                        { title: 'Date Last Modified', field: 'dateModified', type: 'date', editable: false },
                    ]}
                    data={this.state.projects}
                    actions={[
                        {
                            icon: () => (<i className="fas fa-rocket"></i>),
                            tooltip: 'Launch',
                            onClick: (event, rowData) => {
                                this.props.history.push(`/build/${rowData.id}`);
                            }
                        }
                    ]}
                    // options={{
                    //     actionsColumnIndex: -1
                    // }}
                    editable={{
                        onRowUpdate: (newData, oldData) => {
                            return _venue.saveVenue(ensureDefaults(newData), this.props.user.userId).then(_ => this.getData());
                        },
                        // onRowDelete: oldData => {
                        //     return deleteProject(oldData.id)
                        //         .then(p => {
                        //             getProjectDataByProjectId(p.id).then(pd => {
                        //                 deleteProjectData(pd.id);
                        //                 this.getData()
                        //             })
                        //             getProjectAccessByProjectId(p.id).then(pas => {
                        //                 if (pas && pas.length) {
                        //                     pas.forEach(pa => {
                        //                         deleteProjectAccess(pa.id);
                        //                     })
                        //                 }
                        //             })
                        //         });
                        // }
                    }}
                />
                <Modal
                    open={this.state.sharingModal}
                    onClose={this.toggleSharingModal}

                >
                    <Paper
                        p={1}
                        style={{
                            position: "absolute",
                            width: "50%",
                            height: "50%",
                            top: "50%",
                            left: "50%",
                            transform: `translate(-50%, -50%)`
                        }}
                    >
                        <ProjectAccess projectId={this.state.sharingProjectId} />
                    </Paper>
                </Modal>
            </Box>
        );
    }
}

const ensureDefaults = (data) => {
    return {
        ...data,
        visibility: data.visibility || "public",
    }
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(MyProjects);