import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Route, Link, Switch } from 'react-router-dom';
import './App.css';
import { withRouter } from 'react-router-dom';
import { app_login, app_logout } from './redux/actions/userActions';
import { connect } from 'react-redux';
import MyAccount from './components/myAccount';
import MyProjects from './components/myProjects';
import MainApp from './app/mainApp';
import AdminPage from './components/admin';
import { Box, Button, Paper } from '@mui/material';
import LoginCallback from './auth/loginCallback';
import SilentLoginCallback from './auth/silentLoginCallback';
import { _userManager } from './auth/userManager';
import PlayerApp from './app/playerApp';

const redirectToLoginPaths = [
  '/admin',
  '/account',
]

class App extends React.Component {

  clickLogout = () => {
    this.props.app_logout();
    _userManager.signoutRedirect();
  }
  clickLogin = () => {
    _userManager.signinRedirect();
  }

  componentDidMount() {
    const _this = this;
    const pathname = this.props.location?.pathname;

    _userManager.getUser()
      .then(u => {
        if (u) {
          if (u.expired) {
            _userManager.signinSilent()
              .then(_ => {
                // loadChannel();
              })
              .catch(e => {
                _this.props.app_logout();
                _userManager.removeUser();
              })
          }
          else {
            // loadChannel();
          }
        }
        else {
          _this.props.app_logout();
          _userManager.removeUser();
        }
      })
      .catch(e => {
        _this.props.app_logout();
        _userManager.removeUser();
      })
  }

  render() {
    const hasUser = Boolean(this.props.user.userId);
    const isAdminUser = this.props.user.userRole === "admin";
    const MainContainer = () => (
      <div>
        <Navbar sticky="top" bg="dark" variant="dark" expand="lg" style={{ marginBottom: "0px", paddingTop: '0', paddingBottom: '0' }}>
          <Navbar.Brand as={Link} to="/"><img style={{ width: "60px", height: "50px" }} src="/content/images/logo.png" alt="exec venue logo"></img></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/build" style={{ paddingRight: "20px" }}><i className="fas fa-lg fa-tools"></i> Build</Nav.Link>
            </Nav>
            <Nav>
              <NavDropdown.Divider />
              {hasUser && isAdminUser ? <Nav.Link as={Link} to="/admin" ><i className="fas fa-lg fa-crown"></i> Admin</Nav.Link> : ""}
              {hasUser &&
                <NavDropdown style={{ paddingRight: "20px"}} menuVariant="dark" title={<React.Fragment><i className="fas fa-lg fa-user-circle"></i><span> {this.props.user.userName}</span></React.Fragment>}>
                  <NavDropdown.Item as={Link} to="/account"><i className="fas fa-address-card"></i> Account</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/account?tab=projects"><i className="fas fa-image"></i> Scenes</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={this.clickLogout}><i className="fas fa-sign-out-alt"></i> Logout</NavDropdown.Item>
                </NavDropdown>
              }
              {!hasUser ? <Button onClick={this.clickLogin} variant="contained">Login</Button>: ""}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        <Route exact path="/" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/projects" component={MyProjects} />
        <Route path="/account" component={MyAccount} />
        <Route path="/admin" component={AdminPage} />
        <Route path="/auth/callback" component={LoginCallback} />
        <Route path="/auth/silentCallback" component={SilentLoginCallback} />
      </div>
    )
    return (
      <div>
        <Switch>
          <Route path="/build/:id?" component={MainApp} />
          <Route path="/play/:id?" component={PlayerApp} />
          <Route component={MainContainer} />
        </Switch>
      </div>
    );
  }
}


function Home() {
  return (
    <Box p={0} style={{ backgroundColor: "black", height: "90vh" }}>
      <Paper elevation={3} style={{ backgroundColor: "black" }}>
        <img style={{ width: "40%", marginLeft: "30%" }} src="/content/images/logo.png" alt="exec logo" loading="lazy" />
      </Paper>
    </Box>

  )
}

const mapState = (state) => { return { user: state.user } };
const mapDispatch = { app_login, app_logout };
export default connect(mapState, mapDispatch)(withRouter(App));
