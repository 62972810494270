import React from 'react';
import { Fab, Grid, FormControl, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import MicIcon from '@mui/icons-material/Mic';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SelectInput from '../inputs/selectbox';
import SliderInput from '../inputs/slider';
import AudioController from './audioController';
import { inputOptions, supportedAudioTypes } from '../../factories/canvasData';

const blob = window.URL || window.webkitURL;
if (!blob) {
    alert('this browser does not support loading audio files');
}

class InputItem extends React.Component {
    state = {
        inputs: [],
    }
    delete = (e) => {
        e.stopPropagation();
        e.preventDefault();
        return this.props.delete(this.props.input.id);
    }

    onChooseFile = (e) => {
        let value = e.target.files;
        for (var i = 0; i < value.length; i++) {
            var file = value[i];
            if (supportedAudioTypes.includes(file.type)) {
                const audioUrl = blob.createObjectURL(file);
                this.props.propsChange(this.props.input.id, "audioFileUrl", audioUrl)
                this.props.propsChange(this.props.input.id, "audioFileName", file.name)
                this.props.propsChange(this.props.input.id, "audioFileType", file.type)
            } else {
                alert(`${file.type} files are not supported: (${supportedAudioTypes.join(', ')})`)
            }
        }
    }

    onChooseMicInput = (e) => {
        const input = this.state.inputs.find(i => i.value == e.target.value);
        this.props.propsChange(this.props.input.id, "deviceName", input.name)
        this.props.propsChange(this.props.input.id, "deviceId", input.value)
    }

    onSettingChange = (e) => {
        let value = e.target.value;
        if (e.target.type === "number" || e.target.type === "range") {
            value = Number(value);
        }
        else if (e.target.type === "checkbox") {
            value = e.target.checked;
        }

        this.props.propsChange(this.props.input.id, e.target.name, value);
    }

    render() {
        if (this.props.input.type === "mic") {
            navigator.mediaDevices.getUserMedia({ audio: true }).then((_) => {
                navigator.mediaDevices.enumerateDevices().then(devices => {
                    let inputs = devices.filter(d => d.kind == 'audioinput').map(d => { return { value: d.deviceId, name: d.label || "unknown" } })
                    this.setState({ inputs });
                });
            })
        }

        // audioData.loaded[`${this.props.input.id}-input`] = {
        //     updateTime: this.updateTime,
        // };
        const btnStyle = { outline: "none", width: "40px", height: "40px", minHeight: "25px" }
        const input = this.props.input.type == "file" ? (<FormControl fullWidth margin='dense'>
            <div className="custom-file">
                <input type="file" id="file-input" className="custom-file-input" name='file' onChange={this.onChooseFile} style={{ width: "100% " }} />
                <label className="custom-file-label" style={{ color: "white", borderColor: "rgba(255, 255, 255, 0.09)", backgroundColor: "rgba(255, 255, 255, 0.09)" }}><i className="fa fa-file"></i> {this.props.input.audioFileName}</label>
            </div>
        </FormControl>)
            : (<SelectInput name={"mic-input"} value={this.props.input.deviceId} onChange={this.onChooseMicInput} options={this.state.inputs} label={"input"} ></SelectInput>);
        return (
            <Box p={1} style={{ border: "1px solid rgb(50,50,50)", margin: "0", marginTop: "5px", marginBottom: "5px", borderRadius: "5px" }} justifyContent="space-between" alignItems="center">
            <Grid spacing={1} container justifyContent="space-between" alignItems="center">
                    <Grid item style={{ overflowX: "hidden" }}>
                        <div style={{ width: "40px", height: "40px", borderRadius:"5px", textAlign:"center",backgroundColor:"rgb(50,50,50)", paddingTop:"8px" }} {...this.props.dragHandleProps}>
                            {this.props.input.type == "file" ? <InsertDriveFileIcon /> : <MicIcon />}
                        </div>
                    </Grid>
                    <Grid item xs style={{ overflowX: "hidden" }}>
                        <SelectInput name="type" value={this.props.input.type} onChange={this.onSettingChange} options={inputOptions} label="type" />
                    </Grid>
                    <Grid item>
                        <Fab style={btnStyle} title="delete" onClick={this.delete}><CloseIcon /></Fab>
                    </Grid>
                </Grid>
                {input}
                {this.props.input.type == "file" ? <AudioController input={this.props.input} propsChange={this.props.propsChange}/> : ""}
                <SliderInput value={this.props.input.volume} onChange={this.onSettingChange} min={0} max={1} step={.01} label={"volume"} name={"volume"} />
                <SliderInput value={this.props.input.smoothingTimeConstant} onChange={this.onSettingChange} min={0} max={1} step={.01} label={"smoothing time constant"} name={"smoothingTimeConstant"} />
            </Box >
        )
    }
}

export default InputItem;
