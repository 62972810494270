import { Box, Fab, Grid } from "@mui/material";
import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from 'react-redux';
import { addVideoInput, deleteVideoInput, reorderVideoInputs, videoInputPropChange } from "../../../redux/actions/videoInputActions";
import { videoData } from "../../factories/videoData";
import InputItem from "./input";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: '0',
    borderBottomStyle: 'solid',
    borderBottomWidth: 'thin',
    borderBottomColor: 'rgba(0,0,0,.4)',
    background: isDragging ? "rgba(0,0,0,0)" : "rgba(0,0,0,0)",

    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "rgba(0,0,0,0)" : "rgba(0,0,0,0)",
    width: "100%"
});

class InputList extends Component {
    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.props.inputs,
            result.source.index,
            result.destination.index
        );

        this.props.reorder(items);
    }
    addVideoInput = () => {
        this.props.addVideoInput("file");
    }
    delete = (id) => {
        return this.props.delete(id);
    }

    render() {
        const inputs = this.props.inputs;
        const btnStyle = { outline: "none", width: "40px", height: "25px", minHeight: "20px" }
        return (
            <React.Fragment>
                <Grid container spacing={1} alignItems="center" style={{ borderBottomStyle: 'solid', borderBottomWidth: 'thin', borderBottomColor: 'rgba(255,255,255,.4)', paddingBottom: "5px" }}>
                    <Grid item>
                        <Fab style={btnStyle} aria-label="add" onClick={this.addVideoInput}><i className="fa fa-plus"></i></Fab>
                    </Grid>
                </Grid>
                <Box py={1}>
                    {
                        !inputs.length
                            ? <div> <ArrowUpwardIcon /> add a video input</div>
                            :
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {inputs.map((input, index) => (
                                                <Draggable key={String(index)} draggableId={String(index)} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}

                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <InputItem id={input.id} input={input} delete={this.delete} copy={this.copy} propsChange={this.props.videoInputPropChange} dragHandleProps={provided.dragHandleProps} currentTime={videoData.loaded[input.id]?.currentTime} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                    }
                </Box>
            </React.Fragment>

        );
    }
}
const mapCanvasData = (state) => { return { inputs: state.videoInputs } };

const actions = {
    addVideoInput,
    reorder: reorderVideoInputs,
    delete: deleteVideoInput,
    videoInputPropChange: videoInputPropChange,
};
export default connect(mapCanvasData, actions)(InputList)