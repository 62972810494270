import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import TextInputBox from '../inputs/textbox';
import { generateImage, upscaleImage } from '../../../services/imageGeneratorService';
import { Box, Button, Grid, IconButton, LinearProgress } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { v4 as uuidv4 } from 'uuid';
import { base64toFile, download } from '../../utility/utility';
import SliderInput from '../inputs/slider';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import RadioInput from '../inputs/radio';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

class ImageGenerator extends React.Component {
    state = {
        prompt: "",
        negativePrompt: "",
        batch: 1,
        steps: 20,
        isGenerating: false,
        images: [],
        loadingImages: [],
        fullScreenImage: -1,
        aspectRatio: "0",
    }
    getDefaultWidthAndHeight = () => {
        switch (this.state.aspectRatio) {
            case "0": return { width: 512, height: 512 };
            case "1": return { width: 480, height: 270 };
            case "2": return { width: 512, height: 384 };
            case "3": return { width: 540, height: 360 };
            case "4": return { width: 640, height: 270 };
            default: return { width: 512, height: 512 };
        }
    }
    generate = async () => {
        const _this = this;
        if (!_this.props.userId) {
            alert("Please login to use image generator");
            return;
        }
        _this.setState({ isGenerating: true });
        let images = await generateImage({
            prompt: _this.state.prompt,
            negative_prompt: _this.state.negativePrompt,
            batch_size: _this.state.batch,
            steps: _this.state.steps,
            ..._this.getDefaultWidthAndHeight(),
        })
        if (images) {
            _this.setState({ isGenerating: false, images: images.map(i => { return { base64: i } }) });
        }
        else {
            _this.setState({ isGenerating: false });
            alert("something went wrong generating image(s) :(")
        }
    }
    upscale = async (image, index) => {
        const _this = this;
        if (!_this.props.userId) {
            alert("Please login to use image generator");
            return;
        }
        let loadingImages = [..._this.state.loadingImages, index];
        _this.setState({ loadingImages: loadingImages });

        let newImage = await upscaleImage(image);
        let images = [..._this.state.images];
        if (newImage) {
            let oldImage = images[index];
            let upscaled = oldImage.upscaled ? oldImage.upscaled + 1 : 1;
            images.splice(index, 1, { upscaled: upscaled, base64: newImage });
        }
        else {
            alert("something went wrong upscaling image :(")
        }
        loadingImages = _this.state.loadingImages.filter(i => i != index);
        _this.setState({ images: images, loadingImages: loadingImages });
    }
    promptChange = (e) => {
        const _this = this;
        let name = e.target.name;
        let value = e.target.value;

        let state = {};
        state[name] = value;
        _this.setState(state);
    }
    apply = (base64) => {
        const _this = this;
        _this.props.onClose(base64toFile(base64, `${uuidv4()}.png`));
    }
    download = (base64) => {
        const _this = this;
        download(base64, `${uuidv4()}.png`);
    }
    fullscreen = (index) => {
        if (this.state.fullScreenImage == index) {
            this.setState({ fullScreenImage: -1 })
        }
        else {
            this.setState({ fullScreenImage: index })
        }
    }
    close = () => {
        const _this = this;
        _this.props.onClose();
    }
    render() {
        const _this = this;
        let innerWidthMax = (window.innerWidth * .8);
        let width = innerWidthMax > 1200 ? 1200 : innerWidthMax;
        width = width + "px";
        let defaultWidthAndHeight = _this.getDefaultWidthAndHeight();
        let imageStyle = { borderRadius: "10px", width: "300px", height: "auto" };
        let fullScreenImageStyle = { objectFit: "cover", height: "auto", width: "100%" };
        const aspectRadioOptions = [
            { name: "1:1", value: "0" },
            { name: "16:9", value: "1" },
            { name: "4:3", value: "2" },
            { name: "3:2", value: "3" },
            { name: "21:9", value: "4" },
        ]
        return (
            (<Dialog
                open={_this.props.open}
                onClose={_this.close}
                PaperComponent={PaperComponent}
                maxWidth={width}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Image Generator
                </DialogTitle>
                <DialogContent className="scroller">
                    <Box p={1} style={{ width: width }}>
                        <Grid container spacing={1} justifyContent="space-between">
                            <Grid item xs>
                                <TextInputBox name="prompt" value={_this.state.prompt} onChange={_this.promptChange} label="prompt" multiline={true} lineCount={4} />
                            </Grid>
                            <Grid item xs>
                                <TextInputBox name="negativePrompt" value={_this.state.negativePrompt} onChange={_this.promptChange} label="negative prompt (optional)" multiline={true} lineCount={4} />
                            </Grid>
                        </Grid>
                        <RadioInput row name="aspectRatio" label="aspect ratio" onChange={_this.promptChange} value={_this.state.aspectRatio} options={aspectRadioOptions} />
                        <Grid container spacing={1} justifyContent="space-between">
                            <Grid item xs>
                                <SliderInput name="steps" value={this.state.steps} min={1} max={100} step={1} label="detail level" onChange={this.promptChange} />
                            </Grid>
                            <Grid item xs>
                                <SliderInput name="batch" value={this.state.batch} min={1} max={4} step={1} label="number of images" onChange={this.promptChange} />
                            </Grid>
                        </Grid>
                        <Button
                            style={{ height: "50px" }}
                            variant="contained"
                            disabled={_this.state.isGenerating}
                            onClick={_this.generate}
                        >
                            <i class="fas fa-magic"></i>&nbsp;Generate
                        </Button>
                    </Box>
                    {!_this.state.isGenerating ? "" : <LinearProgress />}
                    <hr />
                    {_this.state.images?.length ?

                        <Box p={2} style={{ background: "black", borderRadius: "10px" }}>
                            <Grid p={1} spacing={1} container direction="row" justifyContent="space-around" alignItems="center">
                                {
                                    _this.state.images.map((image, index) => {
                                        let base64 = image.base64;
                                        let isImageLoading = this.state.loadingImages.indexOf(index) != -1;
                                        return (
                                            (<Grid item>
                                                <img src={base64} style={{ filter: isImageLoading ? "blur(2px)" : "", ...(index == this.state.fullScreenImage ? fullScreenImageStyle : imageStyle) }} />
                                                <div style={{ width: "100%", marginTop: "-50px" }}>
                                                    <IconButton onClick={() => _this.apply(base64)} size="large">
                                                        <i class="fas fa-sm fa-check"></i>
                                                    </IconButton>
                                                    <IconButton
                                                        disabled={isImageLoading || image.upscaled >= 2}
                                                        onClick={() => _this.upscale(base64, index)}
                                                        size="large">
                                                        <i class="fas fa-sm fa-magic"></i>
                                                    </IconButton>
                                                    <IconButton onClick={() => _this.download(base64)} size="large">
                                                        <GetAppIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => _this.fullscreen(index)} size="large">
                                                        {index == this.state.fullScreenImage ? <FullscreenExitIcon /> : <FullscreenIcon />}
                                                    </IconButton>
                                                </div>
                                            </Grid>)
                                        );

                                    })

                                }
                            </Grid>
                        </Box>
                        : ""}

                </DialogContent>
            </Dialog>)
        );
    }
}

const mapCanvasData = (state) => { return {} };
const actions = {

};
export default connect(mapCanvasData, actions)(ImageGenerator)
