import { Paper, Box, Tabs, Tab } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import MyProjects from './myProjects';
import MyCollaborativeProjects from './collaborativeProjects';

class MyAccount extends React.Component {
    state = {
        tab: "account",
        projectTab: "my-projects",
    }

    changeTab = (tab) => {
        this.setState({ tab: tab });
    }

    changeProjectTab = (tab) => {
        this.setState({ projectTab: tab });
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const tab = params.get('tab') || "account";
        this.setState({ tab: tab });
    }

    render() {
        const user = this.props.user;
        return (
            <Box p={1} style={{ display: 'flex', flexGrow: 1, height: "90vh", width: "100vw" }}>
                <Tabs
                    style={{ borderRight: `1px solid black`, backgroundColor: "black", height: "100%" }}
                    orientation="vertical"
                    variant="scrollable"
                    value={this.state.tab}
                    onChange={(ev, tab) => this.changeTab(tab)}
                >
                    <Tab label={<span><i className="fas fa-lg fa-address-card"></i> Account</span>} value="account" />
                    <Tab label={<span><i className="fas fa-lg fa-image"></i> Scenes</span>} value="projects" />
                </Tabs>
                {
                    this.state.tab === "account" &&
                    <Paper p={1} style={{ width: "inherit" }}>
                    <Box p={2}>
                        <h4>Account Information</h4>
                        <hr></hr>
                        <h6>First Name</h6>
                        <p>{user.firstName}</p>
                        <h6>Last Name</h6>
                        <p>{user.lastName}</p>
                        <h6>User Name</h6>
                        <p>{user.userName}</p>
                        <h6>Email</h6>
                        <p>{user.email}</p>
                        {/* <h6>Account Type</h6>
                        <p>{user.userRole}</p> */}
                    </Box>
                </Paper>
                }         
                {
                    this.state.tab === "projects" &&
                    <Paper p={1} style={{ width: "inherit" }}>
                        <Tabs
                            style={{ backgroundColor: "rgb(22 22 22)" }}
                            variant="fullWidth"
                            value={this.state.projectTab}
                            onChange={(ev, tab) => this.changeProjectTab(tab)}
                        >
                            <Tab label={<span><i className="fas fa-user"></i> My Projects</span>} value="my-projects" />
                            <Tab label={<span><i className="fas fa-users"></i> Shared With Me</span>} value="collaborative" />

                        </Tabs>
                        {
                            this.state.projectTab === "my-projects" &&
                            <Box>
                                <MyProjects history={this.props.history} />
                            </Box>
                        }
                        {
                            this.state.projectTab === "collaborative" &&
                            <Box>
                                <MyCollaborativeProjects history={this.props.history} />
                            </Box>
                        }
                        {
                            this.state.projectTab === "playlists" &&
                            <Box>
                                <MyProjects history={this.props.history} />
                            </Box>
                        }
                    </Paper>
                }
            </Box>
        );
    }
}


const mapState = (state) => { return { user: state.user } };
const mapDispatch = {};

export default connect(mapState, mapDispatch)(MyAccount);